.layout {
    display: grid;
    grid-template-columns: 3.5rem auto;
    align-items: center;
    gap: var(--space-xs)
}
.brand {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1;
}
.sector {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1;
}

@media(min-width:600px){
    .layout {
        grid-template-columns: 5rem auto;
    }
    .brand {
        font-size: 2.5rem;
    }
    .sector {
        font-size: 1.4rem;
    }
}

@media(min-width:900px){
    .layout {
        grid-template-columns: 6rem auto;
    }
    .brand {
        font-size: 3rem;
    }
    .sector {
        font-size: 1.65rem;
    }
}