.eventCard {
    display: flex;
    flex-direction: column;
    gap: var(--space-lg);

    border-top: 1px solid var(--primary-2);
    padding: var(--space-md) 0;
}
.eventCard:last-child {
    border-bottom: 1px solid var(--primary-2);
}



@media(max-width:599px) {
    .eventCard {
        text-align: center;
    }
}

@media(min-width:600px) {
    .eventCard {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        max-width: 45rem;
        flex-direction: row;
        gap: var(--space-lg);
        align-items: center;
        margin: 0 auto;
    }
}







/* actions */

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.eventLogo {
    position: relative;
}
.eventLogo img {
    object-fit: contain;
    object-position: top right;
    width: 100%;
    height: 100%;
}

@media(min-width:600px) {
    .actions {
        align-items: flex-start;
    }
}



/* button */


.websiteButton {
    display: inline-block;
    text-decoration: none;
    background-color: var(--primary-2);
    padding: .75rem 2rem;
    border-radius: 3rem;
    font-weight: 600;

    transition: all 250ms ease-in-out;
}
.websiteButton svg {
    width: .9rem;
    height: .9rem;
    margin-left: 5px;
    stroke: white;

    transition: all 250ms ease-in-out;
}



@media(hover:hover){
    .websiteButton:hover {
        background-color: white;
        color: var(--dark);
    }
    .websiteButton:hover svg {
        stroke: var(--dark);
    }
}