.layout {
    clip-path: polygon(0 4rem, 100% 0%, 100% calc(100% - 4rem), 0% 100%);
    width: 100%;
    overflow: hidden;
}

.topOnly.layout {
    clip-path: polygon(0 4rem, 100% 0%, 100% 100%, 0% 100%);
}

.wrapper {
    padding: 4rem 0;
}
.topOnly.wrapper {
    padding: 4rem 0 0 0;
}

@media(min-width:800px){
    .layout {
        clip-path: polygon(0 6rem, 100% 0%, 100% calc(100% - 6rem), 0% 100%);
    }
    .wrapper {
        padding: 6rem 0;
    }

    .topOnly.layout {
        clip-path: polygon(0 6rem, 100% 0%, 100% 100%, 0% 100%);
    }
    .topOnly.wrapper {
        padding: 6rem 0 0 0;
    }
}

@media(min-width:1200px){
    .layout {
        clip-path: polygon(0 8rem, 100% 0%, 100% calc(100% - 8rem), 0% 100%);
    }
    .wrapper {
        padding: 8rem 0;
    }

    .topOnly.layout {
        clip-path: polygon(0 8rem, 100% 0%, 100% 100%, 0% 100%);
    }
    .topOnly.wrapper {
        padding: 8rem 0 0 0;
    }
}

@media(min-width:1600px){
    .layout {
        clip-path: polygon(0 10rem, 100% 0%, 100% calc(100% - 10rem), 0% 100%);
    }
    .wrapper {
        padding: 10rem 0;
    }

    .topOnly.layout {
        clip-path: polygon(0 10rem, 100% 0%, 100% 100%, 0% 100%);
    }
    .topOnly.wrapper {
        padding: 10rem 0 0 0;
    }
}


