.layout {
    display: block;
    text-decoration: none;
}
.photoContainer {
    position: relative;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: var(--default-radius);
}

.photo {
    display: block;
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
}