.layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.layout.overlaid {
    position: absolute;
    width: 100%;
}

.shim {
    position: relative;
    pointer-events: none;
    transition: height 350ms ease-in-out; 
}

.brand {
    z-index: 100;
    width: 9rem;
    padding: 1.5rem var(--space-md);
}

@media(min-width:600px){
    .layout {
        /* background-color: rgba(230, 0, 255, 0.343); */
    }

    .shim {
        height: var(--header-height-compact-mobile);
    }

    .brand {
        width: 14rem;
        padding: var(--space-md);
    }
    
    .compact {
        padding: var(--space-sm);
        width: 8rem;
    }

    .nav {
        margin: var(--space-sm);
    }
    .nav.fixed {
        position: fixed;
        z-index: 200;
        top: 0;
        right: 0;
    }

}

@media(min-width:900px){
    
    .brand {
        width: 20rem;
    }
    .shim {
        height: var(--header-height-compact);
    }
    .brand.compact {
        padding: var(--space-sm);
        width: 9rem;
    }

    
}